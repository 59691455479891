<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Vini a catalogo</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.wines.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <div class="container">
      <FormContainer @submitForm="fetchResults(1)">
        <div class="row my-3">
          <FormInputTextWidget
            class="col-auto"
            label="Produttore"
            not-required
            v-model="search.producerName"
          />
          <FormInputTextWidget
            class="col-auto"
            label="Vino"
            not-required
            v-model="search.wineName"
          />
          <SelectWineTypeWidget
            class="col-auto"
            label="Tipologia"
            not-required
            v-model="search.wineTypeId"
          />
          <div class="col"></div>
          <div class="col-auto align-self-end">
            <button type="submit" class="btn btn-primary">Applica filtri</button>
          </div>
        </div>
      </FormContainer>
    </div>

    <FetchList :loading="loading">
      <template v-if="wines && wines.length > 0">
        <AwlTable :heading="tableHeadings" :rows="wines" striped>
          <template #column-key-typeName="{ value, row }">
            {{ value }}<span v-if="row.sparklingWine"> [Spumante]</span>
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'inventory.wines.show', params: { id: row.id } }" />
          </template>
        </AwlTable>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessun vino trovato.</Alert>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';

import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import Alert from '@/libs/Feedback/components/Alert.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import SelectWineTypeWidget from '@/views/components/Form/Widgets/SelectWineTypeWidget.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listWines(null, null)
    .then(res => next(vm => {
      vm.wines = res?.data?.wines || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin],
  components: { FetchList, Alert, AwlTable, ActionShow, AwlPagination, PageTitleContainer, PageTitle, FormContainer, FormInputTextWidget, SelectWineTypeWidget },
  data () {
    return {
      loading: false,
      wines: null,
      search: {
        producerName: null,
        wineName: null,
        wineTypeId: null,
      },
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'producerName', label: 'Produttore' },
        { key: 'name', label: 'Vino' },
        { key: 'typeName', label: 'Tipo' },
      ];
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return this.$api.listWines(page, this.perPage, this.search)
        .then(res => {
          this.wines = res?.data?.wines || [];
          this.setMetadata(res?.data);
        })
        .catch(this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
